@tailwind utilities;

.root {
  font-size: 24px;
}

.root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  background-color: #ffffff;
  width: 100%;
  overflow: clip;
  
  line-height: 140%;
}

.root-container .body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.root-container .body {
  width: 100%;
  max-width: 1200px;
}

.root-container .yellow-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #ffbe02;
}

.root-container .yellow-box {
  padding: 20px;
  width: 100%;
  /* max-width: 1200px; */
}

.yellow-box-container.bottom {
  position: static;
  width: 100%;
}


.root-container .header {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  
  gap: min(1.5rem, 5%);

  padding: 1rem;
  height: 8.5rem;
}

.root-container .header #header-img1{
  height: 5.5rem;
}

.root-container .header #header-img2{
  height: 7.8rem;
}

@media screen and (min-width: 800px) {
  .root-container .header {
    border-bottom: 3px solid theme("colors.yonsei")
  }
}

.root-container .main-image {
  /* position: relative; */
  align-self: center;
  width: 100vw;
  margin-bottom: 4rem; /* 2rem */
}

.root-container .main-image img {
  width: 100%;
}

@media screen and (min-width: 800px) {
  .root-container .carousel-container {
   width: min(44vw, calc(1200px * 0.80 - 18rem));
  }
}

@media screen and (max-width: 799px) {
  .root-container .carousel-container {
    max-width: 72vw;
  }
}

.carousel-container .carousel-item {
  width: 100%;
  aspect-ratio: auto 16 / 9;
}

.carousel-container .slick-dots {
  position: relative;
}

.carousel-container .carousel-button {
  position: relative;
  top: 0.5rem;

  font-size: 2em;
  vertical-align: middle;
}

.carousel-container img, iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.root-container .content-horizontal-flex {
  display: flex;
}

@media screen and (max-width: 799px) {
  .root-container .content-horizontal-flex {
    flex-direction: column;
  }
}

.root-container #desktop-nav-wrapper {
  width: 22%;
  flex-shrink: 0;
  overflow: visible;
}

@media screen and (max-width: 799px) {
  .root-container #desktop-nav-wrapper{
    display: none;
  }
}

.root-container #home-nav {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  top: 3rem;
  padding: 2rem 0;
}

@supports (position: sticky) or (position: -webkit-sticky) {
  .root-container #home-nav {
    position: sticky;
  }
}

.root-container #other-nav {
  display: flex;
  flex-direction: column;

  position: sticky;
  top: 3rem;
  padding: 0 1rem;
  margin-top: 0.5rem;

  border-top: 5px solid var(--color-yonsei);
  border-radius: 3px 3px 0 0;
}

.root-container .other-nav-home {
  color: var(--color-link);
  font-size: 1.5em;
  font-weight: 600;
  text-decoration: underline;
  text-decoration-thickness: 2px;

  padding: 1em 0;
  
  border-bottom: 1px solid #b5b2ad;
}

.root-container #content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 4rem;
}

.grouped-sections {
  display: flex;
  flex-direction: column;
  gap: 2rem; 
}


@media screen and (max-width: 799px) {
  .root-container #content {
    padding: 0 min(2rem, 5%);
  }
}

@media screen and (max-width: 799px) {
  .root-container section {
    scroll-margin-top: 4.4rem;
  }
}

.root-container .title-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.root-container .back-button-bar {
  padding: 4rem 0 1.5rem 0;
}

.root-container .back-button {
  color: var(--color-link);
  font-weight: 400;
  margin-bottom: 0.2em;
}

.root-container .back-button::before {
  display: inline-block;
  
  content: " ";
  background-color: var(--color-link);
  -webkit-mask-image: url("../icons/back.svg");
  mask-image: url("../icons/back.svg");
  background-size: contain;
  background-position: bottom;
  
  width: 1.5em;
  height: 1.5em;
  padding-right: 0.2rem;
  vertical-align: bottom;
}

.root-container .back-button:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.root-container .professor-small {
  align-self: flex-start;

  padding: 2rem 0.5rem;
  padding-bottom: 0.3rem;
  border-top: 4px solid rgb(0, 49, 116, 1);
  border-bottom: 4px solid rgb(0, 49, 116, 1);

  font-size: theme("fontSize.small");
  line-height: normal;
}

@media (max-width: 767px) {
  .root-container .professor-small {
    margin-bottom: 2rem; 
  }
}


@media screen and (max-width: 799px) {
  .professor-small img {
    max-width: 95%;
  }
}

.root-container .list-people {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: min(2rem, 6%);
}

.root-container .list-publications {
  overflow: hidden;
  background-color: #FBF9F7;
  padding: 1.88rem;
  margin-left: -0.2rem;
  
  min-width: 0;
}

.root-container .list-articles {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.5rem;
}

#content-achievements  {
  font-weight: bold;
}

.achievement-link-banner {
  margin-left: 0.5rem; 
}


.root-container .footer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background-color: var(--color-yonsei);
  width: 100%;
  margin-top: 8rem; /* 4rem */
}

.root-container .footer {
  display: flex;
  justify-content: space-between;

  color: #ffffff;

  width: 100%;
  /*max-width: 1200px;*/
  padding: 2rem 2rem 4rem 2rem;
}

@media screen and (max-width: 799px) {
  .root-container .footer {
    flex-direction: column;
    justify-content: flex-start;
    gap: 5rem;

    padding: 2rem;
  }
}

.root-container .footer-info-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
}

.root-container .footer-info-button {
  height: 2.5rem;
  padding-bottom: 0.5rem;
}

.root-container .footer-bar {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  
  gap: 1.5rem;

  padding: 1.5rem;
}

@media screen and (min-width: 800px) {
  .root-container .footer-bar {
    min-height: 8.5rem;
  }

  .root-container .footer-bar img{
    height: 5rem;
  }
}

@media screen and (max-width: 799px) {
  .root-container .footer-bar {
    min-height: 7.5rem;
  }

  .root-container .footer-bar img{
    height: 4rem;
  }
}

@media screen and (max-width: 799px) {
  #header-img2 {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
  }
}

@media screen and (max-width: 799px) {
  #header-img1 {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
  }
}

@media screen and (max-width: 799px) {
  .root-container .header {
    height: 6rem;  
    padding: 0.5rem; 
  }
}


@media screen and (max-width: 799px) {
  .root-container .footer-wrapper {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 799px) {
  .root-container #content {
    gap: 2rem; 
  }
}



@media screen and (max-width: 799px) {
  .root-container {
    font-size: 14px;
  }
}
@media screen and (max-width: 799px) {
  .root-container .header {
    height: 6rem;
    padding: 0.5rem;
  }
  
  .root-container .yellow-box {
    padding: 10px; 
  }
  
  .root-container .footer-wrapper {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 799px) {
  .carousel-container .carousel-button {
    font-size: 1.5em; 
    top: 0.2rem;      
  }
  
  .root-container .carousel-container {
    max-width: 90vw; 
  }
}




@media screen and (max-width: 799px) {
  .root-container .footer-wrapper {
    margin-top: 4rem; 
    padding: 1rem;    
  }
  
  .root-container .footer {
    padding: 1rem 1rem 2rem 1rem;  
  }
  
  .root-container .footer-bar {
    padding: 1rem;
    gap: 1rem;
  }
  
  @media screen and (max-width: 799px) {
    .root-container .footer-bar img {
      height: 3rem; 
    }
  }
}

@media screen and (max-width: 799px) {
  .root-container .footer {
    gap: 1rem;  
    padding: 2rem;
  }
}



/* 모바일 기본: 화면 폭이 작을 때 (예: max-width: 799px) */
/* 모바일에서는 줄바꿈을 위해 block으로 표시 */
@media screen and (max-width: 799px) {
  .line-break-mobile {
    display: block;
  }
}

/* PC 화면: 화면 폭이 800px 이상일 때 */
/* PC에서는 줄바꿈 없이 inline-block으로 표시하면서 좌측 여백으로 띄어쓰기 적용 */
@media screen and (min-width: 800px) {
  .line-break-mobile {
    display: inline-block;
    margin-left: 10px; /* 원하는 띄어쓰기 크기 */
  }
}

.professor-small div {
  white-space: nowrap;
}





 /* .root-container section {
  border: 1px solid #ccc;
}

.carousel-container {
  border: 2px solid #ccc; 
}   */

#content-media {
   margin-top: -2rem; 
}

/* 
.collaborator-item {
  border: 2px solid #ccc; 
  padding: 10px; 
  border-radius: 8px; 
} */
.carousel-item[data-index="8"] {
  cursor: pointer; 
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.carousel-item[data-index="8"]:hover {
  transform: scale(1.05); /* 살짝 확대 */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); 
}

#content-collaborators .collaborators-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  gap: 1.6rem;

  margin-bottom: 4rem;
}

.collaborators-grid .collaborator-item {
  height: 4.3rem;
}

.carousel-dots-container {
  margin: 0;
  padding: 0 0.03rem; /* Mobile padding */
  
}

@media (max-width: 767px) {
  .carousel-dots-container li {
    display: inline-block !important;
    width: auto !important;
    margin: 0 2px !important;
  }
}


@media (min-width: 768px) {
  .carousel-dots-container {
    padding: 0 0.98rem; /* PC padding */
  }
}




/* 모바일에서만 적용 */
@media (max-width: 768px) {
  .carousel-item iframe {
    pointer-events: none !important;
  }
}