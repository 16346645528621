:root {
    
    --color-yonsei: rgba(0, 49, 116, 1);
    --color-link: rgba(0, 115, 224, 1);
  }
  /*
  <flex: https://studiomeal.com/archives/197>

  max-width: 1024px
  max-width: 768px

  display: flex; / inline-flex
  flex-direction: column; (옵션)
  flex-wrap: wrap; (옵션)
  align-items: stretch;
  align-content: stretch; (wrap인 경우만)
  gap: 0.5em / justify-content: space-between; (옵션)

  flex-basis: auto; (min-width) / flex: 0 0 auto

  <font>
  
  font-family: "Arial", Helvetica
  font-size: 2em;
  color: #000000; (옵션)
  font-style: normal; / italic (옵션)
  font-weight: 400; / 700 / 500? (옵션)
  letter-spacing: 0em; (옵션)
  line-height: normal; (옵션)

  <기타>

  width: fit-content;

  position: sticky;

  <이펙트>
  https://css-tricks.com/css-link-hover-effects/

  <custom hook>: 복잡한 컴포넌트 동작이 필요할때
  https://melthleeth.tistory.com/entry/React%EC%97%90%EC%84%9C-Form-Validation%EC%9D%84-%ED%95%B4%EB%B3%B4%EC%9E%90-ft-Custom-Hook

@media screen and (min-width: 640px) {
}
  */
  