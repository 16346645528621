.profile-card {
  width: min(45%, 9.2rem);

}

.profile-card img {
  width: 100%;
  object-fit: cover;
}

.profile-card .wrapper{
  padding: 0.7rem 0 0.7rem 0.7rem;
}

.profile-card .name {
  color: theme("colors.link");
  font-weight: 500;
  line-height: normal;

  word-wrap: normal;
  margin-bottom: 0.5rem;
}

.profile-card .email-wrapper {
  position: absolute;

  margin-left: 0.25rem;
  margin-right: 0;
  cursor: pointer;
}

.profile-card .caption {
  font-size: theme("fontSize.smaller");
  font-weight: 300;
  line-height: 120%;
}

.profile-card .popup {
  word-break: normal;
  overflow-wrap: break-word;

  position: absolute;
  display: none;
  
  background: rgba(0, 0, 0, 0.8);
  color: white;
  font-size: 0.875em;
  padding: 0.5rem 1rem;
  
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  right: 50%;
  top: 150%;
  transform: translate(50%, 0);
}

.profile-card .popup:after {
  position: absolute;

  border: 10px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.8);
  
  bottom: 100%;
  left: 50%;
  margin-left: -10px;
  pointer-events: none;
  content: ' ';
}

.profile-card span:hover + .popup {
  display: block;
}