.vertical-nav-button {
  align-items: center;
  display: inline-flex;

  padding: 1rem;
  position: relative;
  
  border-bottom: 1px solid #b5b2ad;
}

.vertical-nav-button[highlight="true"] {
  background-color: theme("colors.yonsei");
}

.vertical-nav-button .label {
  color: theme("colors.link");
  font-weight: 400;
  vertical-align: middle;
}

.vertical-nav-button[highlight="true"] .label {
  color: theme("colors.white");
}