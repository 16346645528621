@tailwind base;

@layer base {

    * {
        overflow-wrap: anywhere;
    }

	html {
		color: theme("colors.text_black");
        font-family: "DM Sans", sans-serif; /* 따옴표로 한글폰트 추가 */
        font-optical-sizing: auto;
		font-size: 18px;
		font-weight: 400;
        letter-spacing: -0.015em;
        
        scroll-behavior: smooth;
    }

    img {
        max-width: none;
        max-height: none;
        object-fit: cover;
        flex-shrink: 0;
    }

    button {
        border: none;
    }

    h1, h2, h3, h4, h5, h6 {
        color: theme("colors.yonsei");
        line-height: normal;
        margin-bottom: 0.5em;
    }

    p {
        line-height: 140%;
        margin-bottom: 1em;
    }

    h1 {
        font-size: 2em;
        font-weight: 900;
        line-height: normal;
        /*48px*/
    }

    @media screen and (min-width: 1088px) {
        h1 {
            font-size: 2.8em;
        }
    }

    h2 {
        font-size: 1.75em;/*1.5em*/
        font-weight: 900;
        /*32px*/
    }

    @media screen and (min-width: 1088px) {
        h2 {
            font-size: 2.25em;
        }
    }

    h3 {
        font-size: 1.2em;
        font-weight: 700;
        /*24px*/
    }

    @media screen and (min-width: 1088px) {
        h3 {
            font-size: 1.5em;
        }
    }

    h4 {
        font-size: 1em;
        font-weight: 700;
        /*16px*/
    }

    h5 {
        font-size: 0.88em;
        font-weight: 700;
        /*14px*/
    }

    h6 {
        font-size: 0.75em;
        font-weight: 700;
        /*12px*/
    }

    strong {
        font-weight: 700;
    }

    em {
        font-weight: 500;
        font-style: italic;
    }

    b {
        font-weight: 500;
    }

    i {
        font-style: italic;
    }

    u {
        text-decoration: underline;
    }

    ul {
        list-style-type: square;
        padding-left: 1.5rem;

        line-height: 180%;
    }

    ol {
        list-style-type: decimal;
        padding-left: 1.5rem;
    }

    li{
        padding-left: 0.4em;
    }

    li::marker {
        content: "◼  ";
        color: var(--color-link);
        font-size: 16px;
    }

    a {
            color: var(--color-link);
            text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }

    .material-icons {
        font-family: 'Material Symbols Outlined';
        font-weight: normal;
        font-style: normal;

        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        direction: ltr;

        vertical-align: text-top;
        
        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        
        /* Support for IE. */
        font-feature-settings: 'liga';
    }
}