button.content-button {
  justify-self: flex-start;

  background-color: theme("colors.link");
  
  padding: 0.5em 1em;
  margin-top: 2em;
  border-radius: 10em;
}

button.content-button:hover {
  background-color: theme("colors.yonsei");
}

button.content-button:active {
  box-shadow: 1px 1px 3px 3px lightgrey;
}

button.content-button a {
  color: theme("colors.white");
  font-weight: bold;
}